import React from 'react'
import { Link } from 'react-router-dom'

const TopNav = () => {
    return (
        <div><nav class="navbar navbar-expand-lg navbar-light bg-light">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
                <Link class="navbar-brand" to='/'>Logo</Link>

                <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
                    <li class="nav-item">
                        <Link class="nav-link" to='/'>Home</Link>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link" to='/committee-members'>Committee</Link>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link" to='/rules'>Rules</Link>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link" to='/announcements'>Announcements</Link>
                    </li>
                    {
                        localStorage.getItem('incholi_user') ?
                            <li class="nav-item">
                                <Link class="nav-link" to='/member-dashboard'>Dashboard</Link>
                            </li>
                            :
                            <li class="nav-item">
                                <Link class="nav-link" to='/login'>Login</Link>
                            </li>
                    }
                </ul>
            </div>
        </nav></div>
    )
}

export default TopNav