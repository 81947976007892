import { Col, Image, Row } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import parallaxImg from '../assets/home-bg.jpg'
import { link, URI } from '../helper'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const Home = () => {
  const [allRules, setAllRules] = useState([])
  const [gallery, setGallery] = useState([])
  const [allMembers, setAllMembers] = useState([])
  const [announcements, setAnnouncements] = useState([])

  function getRules() {
    axios.get(URI + 'get-all-rules')
      .then(resp => {
        setAllRules(resp.data.response.detail)
      });
  }

  function getGallery() {
    axios.get(URI + 'show-gallery')
      .then(resp => {
        setGallery(resp.data.response.detail)
      });
  }

  function getCommitteeMembers() {
    axios.get(URI + 'get-committe-member')
      .then(resp => {
        setAllMembers(resp.data.response.detail)
      });
  }

  function getAnnouncements() {
    axios.get(URI + 'getAllAnnouncements')
      .then(resp => {
        setAnnouncements(resp.data.response.detail)
      });
  }

  useEffect(() => {
    getRules()
    getGallery()
    getCommitteeMembers()
    getAnnouncements()
  }, [])

  return (
    <div className='home-page mb-5'>
      <div className="hero-section">
      </div>
      {/* <div className='container'>
        <div className='committee-members mt-3'>
          <Row gutter={32}>
            <Col lg={8}>
              <div class="card" style={{ padding: '20px' }}>
                <h4>Rules</h4>
                <div className='home-cards' style={{ overflowY: allRules?.length > 3 ? 'scroll' : 'none' }}>
                  <ul>
                    {
                      allRules?.map(rules => {
                        return (
                          <li>{rules.title}</li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div class="card" style={{ padding: '20px' }}>
                <h4>Announcements</h4>
                <div className='home-cards' style={{ overflowY: announcements?.length > 3 ? 'scroll' : 'none' }}>
                  <ul>
                    {
                      announcements?.map(announcement => {
                        return (
                          <li>{announcement.title}</li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div class="card" style={{ padding: '20px' }}>
                <h4>Committee Members</h4>
                <div className='home-cards' style={{ overflowY: allMembers?.length > 3 ? 'scroll' : 'none' }}>
                  <ul>
                    {
                      allMembers?.map(member => {
                        return (
                          <li>{member.name} ({member.title})</li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className='mt-3 mb-5'>
          <div style={{ textAlign: 'center' }}>
            <h4>Gallery</h4>
          </div>
          <Carousel className='mt-3' responsive={responsive}>
            {
              gallery?.map(img => {
                return (
                  <img src={link + img.filename} style={{ height: '250px', width: '95%' }} />
                )
              })
            }
          </Carousel>
        </div>
      </div> */}
    </div>
  )
}

export default Home