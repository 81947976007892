import { Button, Col, Form, Input, Row } from 'antd'
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2';
import { URI, link } from '../../helper';
import { UserDeleteOutlined, DeleteOutlined } from '@ant-design/icons'

const Committee = () => {
    const [committeeMembers, setCommitteeMembers] = useState({})
    const [allMembers, setAllMembers] = useState([])
    const [committeeLoading, setCommitteeLoading] = useState(false)

    useEffect(() => {
        getCommitteeMembers()
    }, [])

    function onFinishCommitteeMember() {
        setCommitteeLoading(true)
        const formData = committeeMembers
        axios.post(`${URI}add-committe-member`, formData)
            .then(response => {
                if (response.data.status == 200) {
                    Swal.fire({
                        title: 'Success',
                        text: 'Successful',
                        icon: 'success'
                    })
                    getCommitteeMembers()
                    setCommitteeLoading(false)
                }
            })
    }

    function handleCommiteeImages(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;
        createCommitteImage(files[0]);
    }

    function createCommitteImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            setCommitteeMembers({ ...committeeMembers, ['picture']: e.target.result })
        };
        reader.readAsDataURL(file);
    }

    function getCommitteeMembers() {
        axios.get(URI + 'get-committe-member')
            .then(resp => {
                setAllMembers(resp.data.response.detail)
            });
    }

    function deleteMember(id) {
        axios.post(URI + 'delete-committee-member', {
            id: id
        })
            .then(resp => {
                if (resp.data.status === 200) {
                    Swal.fire({
                        title: 'Success',
                        text: 'Deleted Successfully',
                        icon: 'success'
                    })
                    getCommitteeMembers()
                }
            });
    }

    return (
        <div>
            <p style={{ fontSize: '18px' }}>Add Committee member</p>
            <Col md={24}>
                <Input placeholder='Name' onChange={(e) => setCommitteeMembers({ ...committeeMembers, 'name': e.target.value })} className='mt-2' />
                <Input placeholder='Title' onChange={(e) => setCommitteeMembers({ ...committeeMembers, 'title': e.target.value })} className='mt-2' />
                <Input placeholder='Description' onChange={(e) => setCommitteeMembers({ ...committeeMembers, 'description': e.target.value })} className='mt-2' />
                <input type='file' className='mt-2' onChange={(e) => handleCommiteeImages(e)} />
                <div>
                    <Button className="mt-2" onClick={() => onFinishCommitteeMember()} type="primary" loading={committeeLoading} disabled={committeeLoading}>
                        Submit
                    </Button>
                </div>
                {/* <p className='mt-3' style={{ fontSize: '18px' }}>Committee Members:</p> */}
                {/* <ul className='mt-1'>
                    {
                        allMembers?.length > 0 ?
                            allMembers?.map(member => {
                                return (
                                    <div className='d-flex Falign-items-center justify-content-between'>
                                        <li>{member.name}</li>
                                        <UserDeleteOutlined onClick={() => deleteMember(member.id)} style={{ color: 'red' }} />
                                    </div>
                                )
                            })
                            :
                            <p>No members</p>
                    }
                </ul> */}
                <table class="table mt-2 table-striped" style={{ background: 'white', overflowY: 'scroll' }}>
                    <thead style={{ background: '#001529', color: 'white' }}>
                        <tr style={{ textAlign: 'center' }}>
                            <th>S.no</th>
                            <th>Name</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allMembers?.map((member, index) => {
                                return (
                                    <tr style={{ textAlign: 'center' }}>
                                        <td>{index + 1}</td>
                                        <td>{member.name}</td>
                                        <td>{member.title}</td>
                                        <td>{member.description}</td>
                                        <td><UserDeleteOutlined onClick={() => deleteMember(member.id)} style={{ color: 'red' }} /></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </Col>
        </div>
    )
}

export default Committee