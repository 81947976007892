import { Button, Col, Form, Input, Row } from 'antd'
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2';
import { URI, link } from '../../helper';
import { UserDeleteOutlined, DeleteOutlined } from '@ant-design/icons'

const Rules = () => {
    const [ruleLoading, setRuleLoading] = useState(false)
    const [rules, setRules] = useState()
    const [allRules, setAllRules] = useState()

    useEffect(() => {
        getRules()
    }, [])

    function getRules() {
        axios.get(URI + 'get-all-rules')
            .then(resp => {
                setAllRules(resp.data.response.detail)
            });
    }

    function onFinishRules() {
        setRuleLoading(true)
        const formData = rules
        axios.post(`${URI}add-rule`, formData)
            .then(response => {
                if (response.data.status == 200) {
                    Swal.fire({
                        title: 'Success',
                        text: 'Successful',
                        icon: 'success'
                    })
                    getRules()
                    setRuleLoading(false)
                }
            })
    }

    function handleRuleFiles(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;
        createRuleFiles(files[0]);
    }

    function createRuleFiles(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            setRules({ ...rules, ['document']: e.target.result })
        };
        reader.readAsDataURL(file);
    }

    function deleteRule(id) {
        axios.post(URI + 'delete-rule', {
            id: id
        })
            .then(resp => {
                if (resp.data.status == 200) {
                    Swal.fire({
                        title: 'Success',
                        text: 'Deleted Successfully',
                        icon: 'success'
                    })
                    getRules()
                }
            });
    }
    return (
        <div>
            <p style={{ fontSize: '18px' }}>Add Rules</p>
            <Input placeholder='Enter Rule' onChange={(e) => setRules({ ...rules, 'title': e.target.value })} className='mt-2' />
            <input placeholder='Enter Rule' accept='pdf' type='file' onChange={(e) => handleRuleFiles(e)} className='mt-2' />
            <div>
                <Button className="mt-2" onClick={() => onFinishRules()} type="primary" loading={ruleLoading} disabled={ruleLoading}>
                    Submit
                </Button>
            </div>
            {/* <p className='mt-3' style={{ fontSize: '18px' }}>Rules:</p> */}
            {/* <ul className='mt-1'>
                {
                    allRules?.length > 0 ?
                        allRules?.map(rule => {
                            return (
                                <div className='d-flex Falign-items-center justify-content-between'>
                                    <li>{rule.title}</li>
                                    <UserDeleteOutlined onClick={() => deleteRule(rule.id)} style={{ color: 'red' }} />
                                </div>
                            )
                        })
                        :
                        <p>No Rules</p>
                }
            </ul> */}
            <table class="table mt-2 table-striped" style={{ background: 'white', overflowY: 'scroll' }}>
                <thead style={{ background: '#001529', color: 'white' }}>
                    <tr style={{ textAlign: 'center' }}>
                        <th>S.no</th>
                        <th>Title</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        allRules?.map((rule, index) => {
                            return (
                                <tr style={{ textAlign: 'center' }}>
                                    <td>{index + 1}</td>
                                    <td>{rule.title}</td>
                                    <td><UserDeleteOutlined onClick={() => deleteRule(rule.id)} style={{ color: 'red' }} /></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default Rules