import { Col, Row } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { URI, link } from '../helper';

const CommitteeMembers = () => {
    const [committeeMembers, setCommitteeMembers] = useState([]);

    function getCommitteeMembers() {
        axios.get(URI + 'get-committe-member')
            .then(resp => {
                setCommitteeMembers(resp.data.response.detail)
            });
    }

    useEffect(() => {
        getCommitteeMembers()
    }, [])

    return (
        <div className='members-page'>
            <div className='members-bg'></div>
            <div className='container mt-3'>
                <div style={{ textAlign: 'center' }}>
                    <p style={{ fontSize: '30px' }}>Committee Members</p>
                </div>
                <div className='mt-3'>
                    <div className='member'>
                        {
                            committeeMembers?.map(member => {
                                return (
                                    <Row className='mb-3 align-items-center justify-content-center' gutter={16}>
                                        <Col lg={4}>
                                            <img src={link + member.picture} style={{ width: '100%', height: '170px' }} />
                                        </Col>
                                        <Col lg={10}>
                                            <p>
                                                {member.name}
                                            </p>
                                            <p>
                                                {member.title}
                                            </p>
                                            <p>
                                                {member.description}
                                            </p>
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommitteeMembers