import { Button, Col, Form, Input, Row } from 'antd'
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2';
import { URI, link } from '../../helper';
import { UserDeleteOutlined, DeleteOutlined } from '@ant-design/icons'

const GalleryImages = () => {
    const [gallery, setGallery] = useState([]);
    const [galleryImages, setGalleryImages] = useState([])
    const [galleryLoading, setGalleryLoading] = useState(false)

    useEffect(() => {
        getGallery()
    }, [])

    function handleImages(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;
        createImage(files[0]);
    }

    function createImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            setGalleryImages([...galleryImages, e.target.result])
        };
        reader.readAsDataURL(file);
    }

    function addGallery() {
        setGalleryLoading(true)
        const formData = { file: galleryImages }
        axios.post(`${URI}add-gallery`, formData)
            .then(response => {
                if (response.data.status == 200) {
                    Swal.fire({
                        title: 'Success',
                        text: 'Successful',
                        icon: 'success'
                    })
                    getGallery()
                    setGalleryLoading(false)
                }
            })
    }

    function getGallery() {
        axios.get(URI + 'show-gallery')
            .then(resp => {
                setGallery(resp.data.response.detail)
            });
    }

    function deleteGallery(id) {
        axios.post(URI + 'delete-picture', {
            id: id
        })
            .then(resp => {
                if (resp.data.status == 200) {
                    Swal.fire({
                        title: 'Success',
                        text: 'Deleted Successfully',
                        icon: 'success'
                    })
                    getGallery()
                }
            });
    }

    return (
        <div>
            <p style={{ fontSize: '18px' }}>Add Gallery Images</p>
            <input type='file' onChange={(e) => handleImages(e)} />
            <div className='mt-2'>
                {
                    galleryImages.length > 0 &&
                    galleryImages.map(gallery => {
                        return (
                            <img src={gallery} style={{ height: '100px', width: '100px', marginRight: '10px' }} />
                        )
                    })
                }
                <div>
                    <Button className='mt-2' onClick={() => addGallery()} type="primary" loading={galleryLoading} disabled={galleryLoading}>
                        Add
                    </Button>
                </div>
            </div>
            <p style={{ fontSize: '18px', marginTop: '10px' }}>Images</p>
            <Row className='mt-2'>
                <div className='d-flex'>
                    {
                        gallery?.length > 0 &&
                        gallery?.map(img => {
                            return (
                                <div>
                                    <span style={{ position: 'relative', left: '90px', top: '-30px', background: 'white', borderRadius: '50%', padding: '0px 6px', cursor: 'pointer' }} onClick={() => deleteGallery(img.id)}>x</span>
                                    <img src={link + img.filename} style={{ height: '100px', width: '100px', marginRight: '10px' }} />
                                </div>
                            )
                        })
                    }
                </div>
            </Row>
        </div>
    )
}

export default GalleryImages