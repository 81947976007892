import { Row } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { link, URI } from '../helper';
import { DownloadOutlined } from '@ant-design/icons'

const Rules = () => {
    const [rules, setAllRules] = useState([])

    function getRules() {
        axios.get(URI + 'get-all-rules')
            .then(resp => {
                setAllRules(resp.data.response.detail)
            });
    }

    useEffect(() => {
        getRules()
    }, [])

    return (
        <div className='rules-page'>
            <div className='rules-bg'></div>
            <div className='container mt-3'>
                <div style={{ textAlign: 'center' }}>
                    <p style={{ fontSize: '30px' }}>Rules</p>
                </div>
                <div className='mt-3'>
                    <table class="table mt-2 table-striped" style={{ background: 'white', overflowY: 'scroll' }}>
                        <thead style={{ background: '#001529', color: 'white' }}>
                            <tr style={{ textAlign: 'center' }}>
                                <th>S.no</th>
                                <th>Title</th>
                                <th>Document</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                rules?.map((rule, index) => {
                                    return (
                                        <tr style={{ textAlign: 'center' }}>
                                            <td>{index + 1}</td>
                                            <td>{rule.title}</td>
                                            <td><a href={link + rule.document} target='_blank'> <DownloadOutlined /></a> </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Rules