import { Row } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { link, URI } from '../helper';
import { DownloadOutlined } from '@ant-design/icons'

const Announcements = () => {
  const [announcements, setAnnouncements] = useState([])

  function getAnnouncements() {
    axios.get(URI + 'getAllAnnouncements')
      .then(resp => {
        setAnnouncements(resp.data.response.detail)
      });
  }

  useEffect(() => {
    getAnnouncements()
  }, [])

  return (
    <div className='announcements-page'>
      <div className='announcements-bg'></div>
      <div className='container mt-3'>
        <div style={{ textAlign: 'center' }}>
          <p style={{ fontSize: '30px' }}>Announcements</p>
        </div>
        <div className='mt-3'>
          <table class="table mt-2 table-striped" style={{ background: 'white', overflowY: 'scroll' }}>
            <thead style={{ background: '#001529', color: 'white' }}>
              <tr style={{ textAlign: 'center' }}>
                <th>S.no</th>
                <th>Title</th>
                {/* <th>Description</th> */}
                <th>Document</th>
              </tr>
            </thead>
            <tbody>
              {
                announcements?.map((announcement, index) => {
                  return (
                    <tr style={{ textAlign: 'center' }}>
                      <td>{index + 1}</td>
                      <td>{announcement.title}</td>
                      {/* <td>{announcement.description}</td> */}
                      <td><a href={link + announcement.document} target='_blank'> <DownloadOutlined /></a> </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Announcements