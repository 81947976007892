import { Button, Col, Form, Input, Row } from 'antd'
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2';
import { URI, link } from '../../helper';
import { UserDeleteOutlined, DeleteOutlined } from '@ant-design/icons'

const { TextArea } = Input;

const Announcements = () => {
    const [announcements, setAnnouncements] = useState([])
    const [announcementFile, setAnnouncementFile] = useState();
    const [announcementsLoading, setAnnouncementsLoading] = useState(false)

    useEffect(() => {
        getAnnouncements()
    }, [])

    function getAnnouncements() {
        axios.get(URI + 'getAllAnnouncements')
            .then(resp => {
                setAnnouncements(resp.data.response.detail)
            });
    }

    function onFinish(values) {
        values.document = announcementFile;
        setAnnouncementsLoading(true)
        axios.post(URI + 'create-announcement', values)
            .then(resp => {
                if (resp.data.status == 200) {
                    Swal.fire({
                        title: 'Success',
                        text: 'New announcement created successfully',
                        icon: 'success'
                    })
                    setAnnouncementsLoading(false)
                    getAnnouncements();
                }
            })
    }

    function handleAnnouncementFiles(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;
        createAnnouncementFiles(files[0]);
    }

    function createAnnouncementFiles(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            setAnnouncementFile(e.target.result)
        };
        reader.readAsDataURL(file);
    }

    function deleteAnnouncement(id) {
        axios.post(URI + 'delete-announcement', {
            id: id
        })
            .then(resp => {
                if (resp.data.status === 200) {
                    Swal.fire({
                        title: 'Success',
                        text: 'Deleted Successfully',
                        icon: 'success'
                    })
                    getAnnouncements()
                }
            });
    }
    return (
        <div>
            <p style={{ fontSize: '18px' }}>Create announcement</p>
            <Form
                name="add-description-form"
                layout='vertical'
                className='w-100'
                onFinish={onFinish}
            >
                <Row className='justify-content-between gap-4'>
                    <Col md={24}>
                        <Form.Item
                            label="Title"
                            name="title"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="description"
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                        <input accept='pdf' type='file' onChange={(e) => handleAnnouncementFiles(e)} className='mt-2 mb-2' />
                        <Button htmlType='submit' type="primary" loading={announcementsLoading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Form>
            <div className='mt-3'>
                {/* <ul>
                    {
                        announcements?.map(announcement => {
                            return (
                                <li className='mb-2'>
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <p style={{ fontSize: '16px' }}>{announcement.title}</p>
                                            <p style={{ fontSize: '13px' }}>{announcement.description}</p>
                                        </div>
                                        <div><DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} onClick={() => deleteAnnouncement(announcement.id)} /></div>
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul> */}
                <table class="table mt-2 table-striped" style={{ background: 'white', overflowY: 'scroll' }}>
                    <thead style={{ background: '#001529', color: 'white' }}>
                        <tr style={{ textAlign: 'center' }}>
                            <th>S.no</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            announcements?.map((announcement, index) => {
                                return (
                                    <tr style={{ textAlign: 'center' }}>
                                        <td>{index + 1}</td>
                                        <td>{announcement.title}</td>
                                        <td>{announcement.description}</td>
                                        <td><DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} onClick={() => deleteAnnouncement(announcement.id)} /> </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Announcements