import { Col, Row } from 'antd'
import React from 'react'
import logo from '../incholi.png'

const Footer = () => {
    return (
        <div className='footer-section pt-3 mb-4'>
            <div className="container">
                <Row className='justify-content-between'>
                    <Col>
                        <img src={logo} style={{ height: '150px' }} />
                    </Col>
                    <Col>
                        <div>
                            <h4>Head Office</h4>
                            <p>#500 - 4370 Dominion Street <br />
                                Burnaby, BC V5G 4L7
                            </p>
                            <br />
                            <p>
                                Ph: 604.936.9944 <br />
                                Fax: 604.936.9003
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Footer