import './App.css';
import 'antd/dist/antd.css';
import { Route, Routes } from 'react-router-dom';
import Member from './views/Member';
import AllMembers from './views/Member/AllMembers';
import AddMember from './views/Member/AddMember';
import UpdateMember from './views/Member/UpdateMember';
import MemberDashboard from './views/Member/MemberDashboard';
import AllPlots from './views/Plot/AllPlots';
import AddPlots from './views/Plot/AddPlots';
import UpdatePlot from './views/Plot/UpdatePlot';
import Ledgers from './views/Ledgers';
import UpdateLedger from './views/UpdateLedger';
import AddLedger from './views/AddLedger';
import AllNph from './views/Member/AllNph';
import MSI from './views/MSI';
import CreateAnnouncement from './views/CreateAnnouncement';
import AllcommitteeMembers from './views/AllcommitteeMembers';
import Login from './views/Login';
import Register from './views/Register';
import Home from './views/Home';
import TopNav from './components/TopNav';
import Footer from './components/Footer';
import ProtectedRoute from './components/ProtectedRoute';
import { useState } from 'react';
import ProtectedRoutes from './ProtectedRoutes';
import CommitteeMembers from './views/CommitteeMembers';
import Announcements from './views/Announcements';
import Rules from './views/Rules';

function App() {
  const isDashboard = window.location.pathname.includes('member-dashboard')
  const [isLoggedIn, setisLoggedIn] = useState(localStorage.getItem('incholi_user'));

  return (
    <div className="App">
      {
        !isDashboard
          ?
          <TopNav />
          :
          null
      }
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/committee-members" element={<CommitteeMembers />} />
        <Route path="/rules" element={<Rules />} />
        <Route path="/announcements" element={<Announcements />} />
        <Route path='/member-dashboard/*'
          element={
            <ProtectedRoutes isLoggedIn={isLoggedIn}>
              <Member />
            </ProtectedRoutes>
          }
        />
      </Routes>
      {
        !isDashboard ?
          <Footer />
          :
          null
      }
    </div>
  );
}

export default App;
